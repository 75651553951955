<template>
  <div>
    <div class="flex justify-between px-4 mt-6">
      <div>
        <router-link :to="{ name: 'Lobby' }">
          <img
            :src="logo"
            :class="{
              'w-1/6': logo != playdaLogo,
              'w-2/6': logo === playdaLogo,
            }"
          />
        </router-link>
      </div>
      <div v-if="$route.name != 'Rewards' && businessSrc != 'diy'">
        <button
          @click.prevent="goToRewards()"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-2 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Rewards
        </button>
      </div>
      <div>
        <button
          @click.prevent="signOut()"
          class="ml-2 w-20 shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-2 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Sign Out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      businessSrc: this.$store.getters.businessSrc,
      logo: this.$store.getters.businessLogo,
      playdaLogo: process.env.VUE_APP_PLAYDA_LOGO_URL,
    };
  },
  components: {},
  methods: {
    goToRewards() {
      this.$router.push({
        name: "Rewards",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Register",
        params: {
          business: this.$route.params.business,
        },
      });
    },
  },
  mounted() {
    console.log(this.businessSrc);
  },
};
</script>

<style scoped></style>
