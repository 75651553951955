<template>
  <div class="noExpsWrapper w-screen p-4">
    <img
      src="https://playda.s3.ap-south-1.amazonaws.com/gamer/no_more_games.webp"
      class="w-screen"
    />
  </div>
</template>

<script>
export default {
  name: "AllExpsDone",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="postcss">
.noExpsWrapper {
  height: 100dvh;
}
</style>
