<template>
  <div class="wrapper w-screen p-4">
    <div
      class="text-nColorYellow text-shadow-orange-right font-ZuumeSemiBold text-7xl text-center p-4"
    >
      Hang Tight!
    </div>
    <div class="font-InterSemiBold text-lg text-white text-center">
      The Game Jam is about to start!
    </div>
    <div class="font-InterMedium text-base text-nColorLightGray text-center">
      Grab a snack, get comfy, and get ready for some epic gaming fun. Stay
      tuned!
    </div>
    <div>
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/gamer/hang_tight.webp"
      />
    </div>
    <div class="font-InterSemiBold text-lg text-white text-center mt-4">
      Visit
      <a
        href="https://playda.io"
        target="_blank"
        class="text-nColorYellow italic font-InterBold"
        >playda.io</a
      >
      for more information!
    </div>
  </div>
</template>

<script>
export default {
  name: "GameJamNotStarted",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
}
</style>
